import { Box, FormControl, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { SelectAutoComplete, TableComponet } from '../../components';
import UsersService from '../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';

const headers = [
    { id: "funcionario", label: "Funcionario", props: { align: 'left' } },
    { id: "treinamento", label: "Treinamento", props: { align: 'left' } },
    { id: "data_inicio", label: "Data Inicio", props: { align: 'right' } },
    { id: 'data_fim', label: 'Data Fim', props: { align: 'right' } },
    { id: 'data_emissao', label: 'Data Emissão', props: { align: 'right' } },
    { id: 'data_validade', label: 'Data Validade', props: { align: 'right' } },
    { id: 'modo', label: 'Modo', props: { align: 'right' } },
    { id: 'duracao', label: 'Duração', props: { align: 'right' } },
    { id: 'status', label: 'status', props: { align: 'right' } },
];


function FiltroColaborador() {
    const dispatch = useDispatch();
    const [selectedItem, setSelectedItem] = useState({ nome: '', id: '' });
    const [colaboradores, setColaboradores] = useState([]);

    const handleSelectChange = async (newValue) => {
        setSelectedItem({
            nome: newValue ? newValue.nome || newValue.nome_razao_social || '' : '',
            id: newValue ? newValue.id || '' : ''
        });

        if (newValue && newValue.id) {
            await fetchData(newValue.id);
        }
    };

    const fetchData = async (idColaborador) => {
        dispatch(changeloading({ open: true, msg: "Carregando" }));
        
        try {
            const res = await UsersService.getById(idColaborador, 'buscar/treinamento');
            if (res) {
                setColaboradores(res.treinamentos);
            } else {
                setColaboradores([]); 
            }
        } catch (error) {
            console.error("Erro ao buscar os colaboradores:", error);
        } finally {
            dispatch(changeloading({ open: false }));
        }
    };

    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={5}>
                    <FormControl fullWidth>
                        <SelectAutoComplete
                            apiUrl="funcionario"
                            label="Digite o nome do colaborador que deseja filtrar ..."
                            onChange={handleSelectChange}
                        />
                    </FormControl>
                </Grid>
            </Grid>
            {colaboradores.length > 0 && (
                <Box marginTop={5}>
                    <TableComponet
                        headers={headers}
                        data={colaboradores}
                        labelCaption={'Nenhuma usuário encontrado!!'}
                        labelTable={'Usuários'}
                        handlerEditarAction={''}
                        handlerDeletarAction={''}
                    />
                </Box>
            )}
        </Box>
    );
}

export default FiltroColaborador;