import React, { useContext, useEffect, useState } from 'react';
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, FormControl, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import { MaskNome } from '../../utils/mascaras';
import { PatternFormat } from 'react-number-format';
import CustomButton from '../../components/Button/CustomButon';
import { LocationContext } from '../../context/LocationContext';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';
import UsersService from '../../services/UsersService';

const schema = yup.object({
  nome_razao_social: yup.string().required(),
  estado: yup.string().required(),
});

function ClienteEmpresa({ cliente, handleCloseModal, tableUpdateTriggerPai }) {
  const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  const { estado, cidade, setIdEstado } = useContext(LocationContext);
  const [estadoSelecionado, setEstadoSelecionado] = useState('');
  const [cidadeSelecionada, setCidadeSelecionada] = useState('');
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [telefone, setTelefone] = useState('');
  const [telefone_dois, setTelefone_dois] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (cliente) {
    

      // Preenche os campos do formulário com os dados do cliente
      Object.keys(cliente).forEach(key => {
        setValue(key, cliente[key]);
      });

      setEstadoSelecionado(cliente.estado);
      setCidadeSelecionada(cliente.cidade);

      setCpf(cliente.cpf || '');
      setCnpj(cliente.cnpj || '');
      setTelefone(cliente.telefone || '');
      setTelefone_dois(cliente.telefone_dois || '');
      setWhatsapp(cliente.whatsapp || '');

      setValue('telefone', cliente.telefone || '');
      setValue('telefone_dois', cliente.telefone_dois || '');
      setValue('whatsapp', cliente.whatsapp || '');
    }
  }, [cliente, setValue]);



  const handleChangeEstado = (event) => {
    const estadoSelecionado = event.target.value;
    setEstadoSelecionado(estadoSelecionado);

    const estadoEncontrado = estado.find(e => e.sigla === estadoSelecionado);
    if (estadoEncontrado) {
      setIdEstado(estadoEncontrado.id);
    }
  };
  const onSubmitForm = (data) => {
    console.log('dados', data)
    dispatch(changeloading({ open: true, msg: 'Salvando..' }));

    const request = cliente
      ? UsersService.update(cliente.id, data, 'cliente')
      : UsersService.create(data, 'cliente');

    request.then((res) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
      if (!cliente) {
        reset();
      } else {
        handleCloseModal();
        tableUpdateTriggerPai()
      }
    }).catch((error) => {
      console.log(error);
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  return (
    <Box component={Paper} elevation={5} padding={3} bgcolor='#faf7f7'>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container spacing={2}>

          <Grid item xs={12} sm={6} md={5} lg={5}>
            <TextField
              label='Nome / Razao social'
              variant='outlined'
              fullWidth
              size='small'
              {...register("nome_razao_social")}
              onInput={(e) => {
                e.target.value = MaskNome(e.target.value);
                setValue("nome_razao_social", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant='subtitle2'>{errors?.nome_razao_social?.message}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3}>
            <PatternFormat
              label='Cpf'
              format='###.###.###-##'
              mask='_'
              allowEmptyFormatting
              customInput={TextField}
              fullWidth
              size='small'
              value={cpf}
              onValueChange={(values) => {
                setCpf(values.value);
                setValue("cpf", values.value, { shouldValidate: true });
              }}
              {...register("cpf")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={4}>
            <PatternFormat
              label='Cnpj'
              format='##.###.###/####-##'
              mask='_'
              allowEmptyFormatting
              customInput={TextField}
              fullWidth
              size='small'
              value={cnpj}
              onValueChange={(values) => {
                setCnpj(values.value);
                setValue("cnpj", values.value, { shouldValidate: true });
              }}
              {...register("cnpj")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              label='Email'
              type='email'
              variant='outlined'
              fullWidth
              size='small'
              {...register("email")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <PatternFormat
              label='Telefone'
              format='(##) # ####-####'
              mask='_'
              size="small"
              customInput={TextField}
              fullWidth
              value={telefone}
              onValueChange={(values) => {
                setTelefone(values.value);
                setValue('telefone', values.value, { shouldValidate: true });
              }}
              {...register("telefone")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <PatternFormat
              label='Telefone 2'
              format='(##) # ####-####'
              mask='_'
              size="small"
              customInput={TextField}
              fullWidth
              value={telefone_dois}
              onValueChange={(values) => {
                setTelefone_dois(values.value);
                setValue('telefone_dois', values.value, { shouldValidate: true });
              }}
              {...register("telefone_dois")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <PatternFormat
              label='WhatsApp'
              format='(##) # ####-####'
              mask='_'
              size="small"
              customInput={TextField}
              fullWidth
              value={whatsapp}
              onValueChange={(values) => {
                setWhatsapp(values.value);
                setValue('whatsapp', values.value, { shouldValidate: true });
              }}
              {...register("whatsapp")}
            />
          </Grid>



          <Grid item xs={12} sm={6} md={5} lg={4}>
            <TextField
              label='Endereço'
              variant='outlined'
              fullWidth
              size='small'
              {...register("endereco")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField
              label='Bairro'
              variant='outlined'
              fullWidth
              size='small'
              {...register("bairro")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <TextField
              label='Complemento'
              variant='outlined'
              fullWidth
              size='small'
              {...register("complemento")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormControl fullWidth>
              <InputLabel >Selecione o estado</InputLabel>
              <Select
                variant='outlined'
                InputLabelProps={{ shrink: true, }}
                size='small'
                {...register('estado')}
                value={estadoSelecionado}
                onChange={handleChangeEstado}
              >
                {estado.map((estado) => (
                  <MenuItem key={estado.id} value={estado.sigla}>
                    {estado.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant='subtitle2'>{errors?.estado?.message}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={3} xl={2}>
            <FormControl fullWidth>
              <InputLabel >Selecione a cidade</InputLabel>
              <Select
                variant='outlined'
                fullWidth
                size='small'
                {...register('cidade')}
                value={cidadeSelecionada}
                onChange={(e) => setCidadeSelecionada(e.target.value)}
                disabled={!estadoSelecionado}
              >
                {cidade.map((cidade) => (
                  <MenuItem key={cidade.id} value={cidade.nome}>
                    {cidade.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={5} md={3} lg={3}>
            <CustomButton type="submit">
              {cliente ? 'Atualizar' : 'Salvar'}
            </CustomButton>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default ClienteEmpresa;
