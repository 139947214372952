import React from 'react';
import { Box, Button, Card, Grid, Paper, TextField, Typography } from '@mui/material';

import Logo from '../image/logo.png'
import Tacas from '../image/tacas.png'

import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"


import { useDispatch } from 'react-redux';
import { login } from '../store/actions/authActions';
import { useNavigate } from 'react-router-dom';



const schema = yup
  .object({
    name_user: yup.string().required(),
    password: yup.string().required().min(5),
  })

function Login() {
  const { register, handleSubmit: onSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();



  const handleSubmit = async (credentials) => {
    try {
      await dispatch(login(credentials));
      const user = JSON.parse(localStorage.getItem("user"));
      switch (user.group_id) {
        case 1:
          navigate("/dashboard");
          break;
        case 2:
          navigate("/bem-vindo");
          break;
        case 3:
          navigate("/bem-vindo");
          break;
        default:
          navigate("/");  // Em caso de não reconhecer o group_id, redireciona para a homepage ou outro componente
          break;
      }
    } catch (error) {
      console.error("Erro no login:", error);
    }
  };




  return (
    <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center' flexDirection='column'
      sx={{
        background: 'linear-gradient(to bottom, #558b2f, #8bc34a 30%, #9ccc65 70%, #c5e1a5)',
      }}>
      <Box position='relative'>
        <Card>
          <form onSubmit={onSubmit(handleSubmit)}>
            <Box display='flex' flexDirection='column' alignItems='center' justifyContent='center'
              sx={{
                maxWidth: 700, // Largura máxima
                minWidth: 200, // Largura mínima
                height: 300, // Altura adaptável ao conteúdo
                padding: 2, // Espaçamento interno

              }} >
              <Box
                bgcolor="#88be4d"
                padding={2}
                borderRadius="10px"
                position="absolute"
                sx={{
                  top: '-125px', // Responsivo para ajustar a posição superior
                  width: { xs: '90%', sm: '80%', md: '70%', lg: '490px' }, // Ajusta a largura dinamicamente
                  maxWidth: '520px', // Largura máxima          
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center", // Centraliza o texto
                }}
              >
                <img src={Logo} alt="Descrição da Imagem" style={{ marginTop: '8px', width: 'auto', height: '130px' }} />

              </Box>
              <Grid container spacing={3} >
                <Grid item xs={12}>
                  <TextField
                    label="Usuario"
                    variant="outlined"
                    size='small'
                    fullWidth
                    {...register("name_user")}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Senha"
                    type="password"
                    size='small'
                    fullWidth
                    {...register("password")}
                    color="secondary"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button type='submit' variant='contained' fullWidth>Login</Button>
                </Grid>
              </Grid>
            </Box >
          </form>
        </Card>
      </Box>   

    </Box>
  )

}

export default Login